#milan-linkedin .hero {
  background-color: #cfd6d6;
}

#milan-linkedin .stack-sm-hero-bg {
  /* 1150 x 736 */
  background-image: url("./assets/background/Linkedin_Hero_XS.webp");
  background-size: 100% auto;
  background-repeat: no-repeat;
  width: 100%;
  height: 0;
  /* 736 / 1150 = 0.64 */
  padding-top: 64%;
}

@media (min-width: 576px) {
  #milan-linkedin .stack-sm-hero-bg {
    /* 1534 x 827 */
    background-image: url("./assets/background/Linkedin_Hero_SM.webp");
    background-size: 100% auto;
    background-repeat: no-repeat;
    width: 100%;
    height: 0;
    /* 827 / 1534 = 0.5391134289 */
    padding-top: 53.91134289%;
  }
}

@media (min-width: 768px) {
  #milan-linkedin .hero {
    background-image: url("./assets/background/Linkedin_Hero_MD.webp");
    background-size: cover;
    background-position: center;
  }
}

@media (min-width: 992px) {
  #milan-linkedin .hero {
    background-image: url("./assets/background/Linkedin_Hero_LG.webp");
  }
}

@media (min-width: 1200px) {
  #milan-linkedin .hero {
    background-image: url("./assets/background/Linkedin_Hero_XL.webp");
  }
}

@media (min-width: 1400px) {
  #milan-linkedin .hero {
    background-size: contain;
  }
}

#linkedin-backgrounds {
  background-color: rgba(245, 245, 245, 255);
}
.bg-card {
  cursor: pointer;
}
#linkedin-milestones {
  background-color: rgba(235, 235, 235, 255);
}

#live-clinics-calculator {
  background-color: #00a5b4;
}

#live-clinics-calculator .divider {
  background-image: url("../shared/assets/Linkedin-DivAngle.svg");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
  margin-bottom: -1px;
}
