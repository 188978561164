/* @import '~bootstrap/dist/css/bootstrap.min.css'; */
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,400i,600,600i,700');

:root {
  --main-red: #f93500;
  --red-disabled: rgba(255, 54, 0, .5);
  --main-turquoise: #3BD0EA;
  --light-blue: #8cdfe6;
  --main-navy: #003a44;
  --navy-disabled: rgba(0, 58, 68, .5);
  --light-shadow: 0 9px 6px -7px #777;
  --heavy-shadow: 0px 9px 16px -2px #777;
  --inset-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
  --light-bg: rgba(207, 246, 255, 1.00);
  --light-blue-bg: #F5FAFD;

  /* New Design */
  --milan-navy: #003542;
  --milan-blue: #0088AB;
  --milan-secondary-blue: #90d5d5;
  --milan-turquoise: #00A5B4;
  --fallback-blue: #4DBBC7;
  --milan-red: #F13A1A;
  --milan-white: #f9fbfb;
  --milan-off-white: #EFF7F7;
  --milan-grey: rgb(194, 194, 194);
  --milan-light-grey: #efefef;
  --border-radius: 5px;
  --default-transition: all 150ms ease-in-out;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  text-decoration: none;
}

body {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  color: var(--main-navy);
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
  padding-left: 0;
}

#home-page {
  background-color: #EDEDED;
}

#home-page .hero {
  background-image: url('https://milanlasergatsby.com/internal/assets/Hero_Mobile.webp');
  min-height: 340px;
  background-position: center top;
}

#home-page .thirdBG {
  background-image: url('https://milanlasergatsby.com/internal/assets/Second_mobile.png');
  background-position: center top;
  min-height: 350px;
  background-color: #C9C9C9;
  background-size: contain;
  background-repeat: no-repeat;
}

#home-page .thirdBG .spacer {
  max-width: 462px;
  margin-top: 270px;
}

.z-index-100 {
  z-index: 100;
}

#home-page h1.head {
  font-size: calc(38px + (70 - 38) * ((100vw - 300px) / (1600 - 300)));
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: brandon-grotesque, sans-serif;
  font-style: normal;
}

#custom-graphic-form {
  background-color: #8BD1D7;
}

.border-color-light-grey {
  border-color: rgba(194, 194, 194, .4) !important;
}

.milan-fs-7 {
  font-size: .75rem;
}

/* ******************* Buttons | New Design ******************* */
/* Milan Default Button */
.milan-btn {
  border: none;
  transition: var(--default-transition);
  border-radius: var(--border-radius);
  padding: .75rem 0;
  text-align: center;
  font-size: 1.125rem;
  display: inline-block;
  width: 245px;
  line-height: 1.875rem;
  padding-right: .5rem;
  padding-left: .5rem;
  cursor: pointer;
  text-decoration: none;
}

.milan-btn:disabled {
  opacity: .7;
  cursor: not-allowed;
}

/* Milan Store Button */
.milan-store-btn {
  background-color: var(--milan-off-white);
  color: var(--milan-navy);
  cursor: pointer;
  text-align: center;
  font-weight: 300 !important;
  padding: .5rem .5rem !important;
  width: 100% !important;
}

.milan-store-btn:hover,
.milan-store-btn.selected {
  background-color: var(--milan-navy);
  color: var(--milan-white);
}

/* Milan Primary Button */
.milan-primary-btn {
  background-color: var(--milan-blue);
  color: var(--milan-white);
}

.milan-primary-btn:hover {
  background-color: var(--milan-turquoise);
  color: var(--milan-white);
}

.milan-primary-btn.column-fallback-btn {
  background-color: var(--milan-grey);
  color: var(--milan-navy);
}

/* Milan Secondary Button */
.milan-secondary-btn {
  background-color: var(--milan-secondary-blue);
  color: var(--milan-navy);
}

.milan-secondary-btn:hover {
  background-color: var(--milan-white);
  color: var(--milan-navy);
}

.milan-secondary-btn:disabled {
  background-color: var(--milan-secondary-blue) !important;
  color: var(--milan-navy) !important;
}

/* Milan Navy Button */
.milan-navy-btn {
  background-color: var(--milan-navy);
  color: var(--milan-white);
}

.milan-navy-btn:hover {
  background-color: var(--milan-white);
  color: var(--milan-navy);
}

.milan-navy-btn:disabled {
  background-color: var(--milan-navy) !important;
  color: var(--milan-white) !important;
}

.milan-navy-btn.column-fallback-btn {
  background-color: var(--fallback-blue);
  color: var(--milan-navy);
}

/* Milan Red Button */
.milan-cta-btn,
.milan-red-btn {
  background-color: var(--milan-red);
  color: var(--milan-white);
}

.milan-red-btn:hover {
  background-color: var(--milan-white);
  color: var(--milan-red);
}

.milan-cta-btn:hover,
.milan-cta-btn:focus,
.milan-cta-btn:focus-visible,
.milan-cta-btn:active {
  background-color: var(--milan-turquoise) !important;
  color: var(--milan-white);
}

.milan-red-btn:disabled {
  background-color: var(--milan-red) !important;
  color: var(--milan-white) !important;
}

/* Milan Grey Button */
.milan-grey-btn {
  background-color: var(--milan-grey);
  color: var(--milan-navy);
}

.milan-grey-btn:hover {
  background-color: rgba(194, 194, 194, .5);
  color: var(--milan-navy);
}

/* Milan Fallback Button */
.milan-fallback-btn {
  background-color: var(--milan-turquoise);
  color: var(--milan-white);
}

.milan-fallback-btn:hover {
  background-color: var(--milan-white);
  color: var(--milan-turquoise);
}

/* ******************* Text Colors | New Design ******************* */
.milan-text-primary {
  color: var(--milan-blue) !important;
}

.milan-text-secondary {
  color: var(--milan-secondary-blue) !important;
}

.milan-text-navy {
  color: var(--milan-navy) !important;
}

.milan-text-tur {
  color: var(--milan-turquoise) !important;
}

.milan-text-red {
  color: var(--milan-red) !important;
}

.milan-text-white {
  color: var(--milan-white) !important;
}

/* ******************* Background Color | New Design ******************* */
.milan-bg-navy {
  background-color: var(--milan-navy);
}

.milan-bg-primary {
  background-color: var(--milan-blue);
}

.milan-bg-secondary {
  background-color: var(--milan-secondary-blue);
}

.milan-bg-red {
  background-color: var(--milan-red);
}

.milan-bg-tur {
  background-color: var(--milan-turquoise);
}

.milan-bg-white {
  background-color: var(--milan-white);
}

.milan-bg-off-white {
  background-color: var(--milan-off-white);
}

/* ************************************ */

.cta-btn {
  border: none;
  transition: all 150ms ease-in-out;
}

.dark-grey-btn {
  background-color: darkgray;
  color: #fff;
}

.red-bg-btn {
  background: var(--main-red);
  color: #fff;
}

.dark-grey-btn:hover,
.red-bg-btn:hover {
  color: #fff;
  background: var(--main-turquoise);
}

.red-bg-btn:disabled {
  background-color: var(--red-disabled);
  cursor: not-allowed;
}

.light-btn {
  background: var(--main-turquoise);
  color: #fff;
}

.light-btn:hover {
  background: var(--main-red);
  color: #fff;
}

form label sup {
  color: var(--main-red);
  font-size: .5rem;
  top: -.53rem;
}

.react-datepicker-wrapper {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

/* .hero { margin-top: 62px; } */
.background {
  background-size: cover;
  background-repeat: no-repeat;
}

#milan-deck p {
  line-height: 2;
}

.brandon-grotesque {
  font-family: brandon-grotesque, sans-serif;
  font-weight: 400;
  font-style: normal;
}

.hero .pop {
  font-size: 1.8rem;
  line-height: 2rem;
}

#milan-deck .bg-l-blue {
  background-color: #F5FAFE;
}

#milan-deck .bg-blue {
  background-color: #10A2AB;
}

#milan-deck .bg-grey {
  background-color: #E5E5E5;
}

#milan-deck .bg-bright {
  background-color: #8BD1D7;
}

#milan-deck .hero {
  background-image: url('https://milanlasergatsby.com/internal/assets/custom-graphics-hero-mobile.webp');
  background-size: contain;
  background-position: center top;
}

#milan-deck .hero .bg-white img {
  max-width: 200px;
}

@media (min-width: 375px) {
  .hero .pop {
    font-size: 2.5rem;
    line-height: 3rem;
  }

  #home-page .thirdBG {
    min-height: 370px;
  }

  #home-page .thirdBG .spacer {
    margin-top: 330px;
  }

}

@media (min-width: 576px) {
  #milan-deck .hero {
    background-image: url('https://milanlasergatsby.com/internal/assets/custom-graphics-hero-tablet.webp');
    background-size: contain;
    background-position: center top;
  }

  #home-page .thirdBG {
    background-position: left top;
  }

  #home-page .thirdBG .spacer {
    margin-top: 0px;
  }
}

@media (min-width: 767px) {
  #home-page .hero {
    background-image: url('https://milanlasergatsby.com/internal/assets/Hero_Desktop.webp');
    min-height: 710px;
    background-position: center bottom;
  }

  #home-page .translate-top {
    transform: translateY(-250px);
    margin-bottom: -250px;
  }

  #home-page .thirdBG {
    background-image: url('https://milanlasergatsby.com/internal/assets/Second_tablet.png');
    background-color: transparent;
    background-size: cover;
    background-position: center top;
  }

  /* New Design */
  .milan-btn {
    padding: .75rem 1.5625rem;
    font-size: 1rem;
    line-height: 1.75rem;
    width: auto;
  }

  .milan-primary-btn.column-fallback-btn {
    background-color: var(--milan-blue);
    color: var(--milan-white);
  }

  .milan-primary-btn.column-fallback-btn:hover {
    background-color: var(--milan-turquoise);
    color: var(--milan-white);
  }

  .milan-navy-btn.column-fallback-btn {
    background-color: var(--milan-navy);
    color: var(--milan-white);
  }

  .milan-navy-btn.column-fallback-btn:hover {
    background-color: var(--milan-white);
    color: var(--milan-navy);
  }
}

@media (min-width: 992px) {
  .milan-btn {
    padding: .5rem 1rem;
  }
}

@media (min-width: 1200px) {
  #home-page .hero {
    min-height: 700px;
  }

  #home-page .thirdBG {
    background-image: url('https://milanlasergatsby.com/internal/assets/Second_Desktop.png');
  }
}

@media (min-width: 1024px) {
  .hero .pop {
    font-size: 3.8rem;
    line-height: 4rem;
  }

  #milan-deck .hero {
    background-image: url('https://milanlasergatsby.com/internal/assets/Deck-hero.webp');
  }
}

@media (min-width: 1500px) {
  #home-page .hero {
    background-image: url('https://milanlasergatsby.com/internal/assets/Hero_XL.webp');
    min-height: 700px;
  }

  #home-page .thirdBG {
    background-image: url('https://milanlasergatsby.com/internal/assets/Second_XL.png');
  }
}