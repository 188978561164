@import '~leaflet/dist/leaflet.css';

#find-milan {
  background-color: rgba(144, 213, 213, .2);
}

#find-milan h1 {
  line-height: 2rem;
}

#find-milan .logo img {
  max-width: 100px;
}

#find-milan .fm-btn {
  background-color: var(--milan-blue);
  transition: var(--default-transition);
  border-color: var(--milan-blue);
  color: var(--milan-white);
}

#find-milan .fm-btn:hover {
  background-color: var(--milan-turquoise);
  color: var(--milan-white);
}

#find-milan .highlighted {
  border: 2px solid var(--milan-turquoise);
  box-sizing: content-box;
}

.leaflet-container {
  height: 450px;
  width: 100%;
  border: 2px solid var(--milan-turquoise);
  border-radius: var(--border-radius);
}

.leaflet-container .leaflet-popup-content-wrapper,
.leaflet-container .leaflet-popup-tip {
  background-color: var(--milan-off-white);
}

.you-are-here {
  background: var(--milan-blue);
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(0, 165, 180, 0.6);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 165, 180, 0.6);
  }

  70% {
    box-shadow: 0 0 0 6px rgba(0, 165, 180, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(0, 165, 180, 0);
  }
}

.fm-search {
  border: none;
  background-color: var(--milan-white);
  font-size: 14px;
  height: 44px;
  line-height: 36px;
  outline: none;
  flex: 1 1 auto;
  padding-left: 10px;
  padding-right: 10px;
}

input.fm-search:-webkit-autofill,
input.fm-search:-webkit-autofill:hover,
input.fm-search:-webkit-autofill:focus {
  border: none;
  -webkit-text-fill-color: var(--milan-navy);
  -webkit-box-shadow: none;
  transition: background-color 5000s ease-in-out 0s;
}

#find-milan #nearby-stores-list a:hover {
  opacity: .75;
}

#find-milan .sort-type {
  height: 100%;
  padding: .2rem .75rem;
  border: none;
  background-color: var(--milan-light-grey);
  color: var(--milan-grey);
}

#find-milan .sort-type.active {
  background-color: var(--milan-blue) !important;
  color: var(--milan-white) !important;
  border-color: var(--milan-blue) !important;
}

#find-milan .sort-type:hover {
  background-color: #d9d9d9;
  color: #707070;
}

#states-dropdown-section {
  min-height: 310px;
  list-style: none;
  padding-left: 0px;
}

#states-dropdown-section .btn-group {
  width: 100%;
  max-width: 240px;
}

#states-dropdown-section .btn-group .dropdown-menu {
  width: 100%
}

#states-dropdown-section .btn-group__state button {
  border-radius: var(--border-radius) !important;
}

#states-dropdown-section .btn-group__state .dropdown-menu {
  padding: 5px;
  background-color: var(--milan-off-white);
}

#states-dropdown-section .btn-group__state>button {
  background-color: var(--milan-blue);
  width: 100%;
  transition: var(--default-transition);
  border-color: var(--milan-blue);
}

#states-dropdown-section .btn-group__state>button:hover {
  background-color: var(--milan-turquoise);
  border-color: var(--milan-turquoise);
}

#states-dropdown-section .btn-group__state .dropend>.dropdown-toggle,
#states-dropdown-section .btn-group__metro>button {
  background-color: var(--milan-off-white);
  color: var(--milan-navy);
  width: 100%;
  border-radius: 0%;
  border: none;
  text-align: left;
  white-space: normal;
  border-bottom: 1px solid rgba(194, 194, 194, .3);
}

#states-dropdown-section .btn-group__state .dropdown-menu>.dropdown-item {
  padding: .375rem .75rem;
  white-space: normal;
  border-bottom: 1px solid rgba(194, 194, 194, .3);
}

#states-dropdown-section .btn-group__state .dropend:last-child>.dropdown-toggle,
#states-dropdown-section .btn-group__state .dropdown-menu>.dropdown-item:last-child {
  border-bottom: none;
}

#states-dropdown-section .btn-group__state .dropend>.dropdown-toggle:hover,
#states-dropdown-section .btn-group__metro>button:hover {
  background-color: var(--bs-dropdown-link-hover-bg);
}

#states-dropdown-section .btn-group button::after {
  margin-left: .75rem;
}

#find-milan .toast {
  position: fixed;
  bottom: 50px;
  left: 3rem;
  background-color: #fff;
  transform: translateX(-120%);
  animation: slideRight 500ms ease-in-out 3s normal forwards;
  z-index: 999;
}

@keyframes slideRight {
  0%   {transform: translateX(-120%);}
  95%  {transform: translateX(5%);}
  100% {transform: translateX(0%);}
}

@media (min-width: 576px) {
  .leaflet-container {
    height: 600px;
  }
}

@media (min-width: 767px) {
  .leaflet-container {
    height: 820px;
  }
}

@media (min-width: 1400px) {
  #find-milan .logo img {
    max-width: 120px;
  }
}