@import '~bootstrap/dist/css/bootstrap.min.css';
:root {
  --light-coral: #E6BBB1;
  --mild-coral: #E14F3D;
  --main-coral: #F93500;
  --milan-blue: #01718f;
  --lighter-blue: #BBDDF6;
  --light-blue: #9CDCE7;
  --mild-blue: #00B0BE;
  --main-navy: #003B4A;
  --navy-disabled: rgba(0, 58, 68);
  --light-blue-bg: #F5FAFD;
  --light-khaki: #97AB4D;
  --main-khaki: #738738;
  --border-color: #dee2e6;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  text-decoration: none;
}
body {
  font-family: 'Open Sans',sans-serif;
  font-size: 16px;
  color: var(--main-navy);
}
.logo {
  max-width: 140px;
}
.reader .input-group-text > sup {
  display: none;
}

/* ********* Buttons ************ */
button, input[type=submit] {
  border-radius: 0 !important;
  transition: all 200ms ease-in-out;
}
.navy-bg-btn {
  background-color: var(--main-navy);
  width: 100%;
  color: #fff;
  transition: all 200ms ease-in-out;
}
.navy-bg-btn:hover, .navy-bg-btn:focus, .navy-bg-btn.active{
  background-color: var(--milan-blue) !important;
  color: #fff;
}
.navy-bg-btn:disabled {
  background-color: var(--main-navy);
}
.auth-btn, .auth-btn:hover, .auth-btn:focus {
  max-width: 120px !important;
  background-color: var(--milan-blue);
  width: 100%;
}
.auth-btn:hover, .auth-btn:focus {
  color: var(--lighter-blue);
}
button:disabled{
  cursor: not-allowed;
}
.btn-edit {
  background-color: var(--mild-blue);
  color: #fff;
}
.btn-edit:hover, .btn-edit:focus  {
  color: #fff;
  background-color: var(--milan-blue);
}
.btn-delete {
  background-color: var(--mild-coral);
  color: #fff;
}
.btn-delete:hover, .btn-delete:focus  {
  color: #fff;
  background-color: var(--main-coral);
}
.btn-cancel {
  background-color: #B1E1EF;
}
.btn-cancel:hover, .btn-cancel:focus {
  background-color: #65C3E2;
}
.btn-submit {
  background-color: var(--main-navy);
  color: #fff;
}
.btn-submit:hover, .btn-submit:focus {
  background-color: var(--milan-blue);
  color: #fff;
}
.btn-khaki {
  background-color: var(--light-khaki);
  color: #fff;
}
.btn-khaki:hover, .btn-khaki:focus {
  background-color: var(--main-khaki);
  color: #fff;
}

/* ********* Forms Inputs ************ */
.form-control, .form-select, .input-group-text {
  border-radius: 0;
}
.input-group .input-group-text {
  background-color: var(--milan-blue);
  color: #fff;
  min-width: 90px;
  display: block;
}
#metro-details .input-group .input-group-text,
#add-new .input-group .input-group-text {
  min-width: 160px;
}
.disabled-bg {
  background-color: #e9ecef !important;
  color: inherit !important;
}
#selections-dropdowns .form-select {
  padding-top: .75rem;
  padding-bottom: .75rem;
  font-weight: 600;
}
#selections-dropdowns .input-group-prepend label {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

/* ********* Control Panel ************ */
.control-panel .tab-content {
  padding-left: 0px;
  padding-right: 0px;
  background-color: var(--light-blue-bg);
  border: 1px solid;
  border-color: var(--light-blue-bg) var(--border-color) var(--border-color);
}
.control-panel .nav-tabs .nav-item > button {
  color: var(--main-navy);
  font-weight: bold;
}
.control-panel .nav-tabs .nav-item.show .nav-link,
.control-panel .nav-tabs .nav-link.active,
.control-panel .nav-tabs .nav-link:hover {
  background-color: var(--light-blue-bg);
  border-bottom: 1px solid var(--light-blue-bg);
  color: var(--milan-blue)
}
#control-panel .row > .tab-content {
  min-height: 500px;
}
#control-panel .tab-content .tab-content {
  border: none;
}
table th {
  color: var(--main-navy)
}
#global-data form label.input-group-text {
  min-width: 210px;
}
#global-data form .footer-height label.input-group-text {
  min-width: 90px;
}
.corner-flag::before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  width: 0;
  height: 0;
  border-right: 10px solid transparent;
  border-bottom: 10px solid transparent;
}
.corner-flag-red::before {
  border-top: 10px solid rgba(249, 53, 0, .7);
  border-left: 10px solid rgba(249, 53, 0, .7);
}
.corner-flag-green::before {
  border-top: 10px solid rgb(0, 210, 70);
  border-left: 10px solid rgb(0, 210, 70);
}

/* ********* text color ************ */
.text-coral {
  color: var(--main-coral);
}
.text-khaki {
  color: var(--main-khaki);
}
.text-navy {
  color: var(--main-navy)
}
.light-blue {
  color: var(--light-blue) !important;
}
.main-blue {
  color: var(--milan-blue);
}

/* ********* Backgrounds ************ */
.navy-bg {
  background: var(--main-navy);
}
.bg-blue {
  background-color: var(--milan-blue) !important;
}
.bg-khaki {
  background-color: var(--main-khaki);
}
.bg-mild-blue {
  background-color: var(--mild-blue);
}
.bg-mild-coral {
  background-color: var(--mild-coral);
}
#auth .card {
  background-color: var(--light-blue-bg);
}
/* ********* List Group Item ************ */
.list-group-item {
  cursor: pointer;
}
.list-group-item:focus, .list-group-item:focus-visible {
  background-color: var(--milan-blue);
  color: #fff;
  border: none;
}
.list-group-item .badge {
  width: 85px;
}
.list-group-item.btn-outline-dark {
  border-color: var(--main-navy) !important;
  color: var(--main-navy);
  transition: all 150ms ease-in-out;
  padding-top: .75rem;
  padding-bottom: .75rem;
}
.list-group-item.btn-outline-dark:hover {
  background-color: var(--milan-blue);
  color: #fff;
}
/* ********* Pagination ************ */
.pagination .page-item .page-link {
  color: var(--milan-blue);
}
.pagination .page-item.active .page-link {
  background-color: var(--milan-blue);
  color: #fff;
}

/* ********** Other ********** */
.divider-line {
  border-bottom: 4px solid var(--milan-blue);
  width: 250px;
  margin-bottom: 2rem;
}
a {
  text-decoration: none;
}
a[href^="tel:"] {
  white-space: nowrap;
}
.navy-border {
  border: 1px solid var(--main-navy);
}
.pointer {
  cursor: pointer;
}
label sup {
  font-size: .5em;
  color: var(--main-coral)
}
.search-results {
  z-index: 100;
}
.search-input input {
  min-width: 220px !important;
}
#clinic-site-app .toast {
  position: fixed;
  bottom: 30px;
  right: 40px;
  background-color: #fff;
  transform: translateX(120%);
  animation: slideLeft 500ms ease-in-out 3s normal forwards;
  z-index: 999;
}
@keyframes slideLeft {
  0%   {transform: translateX(120%);}
  95%  {transform: translateX(-5%);}
  100% {transform: translateX(0%);}
}

@media (min-width: 576px) {
  #clinic-site-app {
    background-color: var(--light-blue-bg);
    padding: 20px;
  }
}
@media (min-width: 992px) {
  #add-new-state form {
    min-height: 224px;
  }
}
