#home .btn {
   width: 280px;
}

#home .btn-slide {
   width: 270px;
}

#home .btn-blue {
   background-color: #0088ab;
}
#home .btn-blue:hover{
   background-color: #0088ab;
   transition: 0.3s;
   opacity: 0.8;
}

#home .btn-red {
   background-color: #f13a1a;
}
#home .btn-red:hover{
   background-color: #f13a1a;
   transition: 0.3s;
   opacity: 0.8;
}


:root {
   --milan-light-turquoise: #e4f3f3;
   --milan-turquoise: #00a5b4;
   --milan-dark-turquoise: #0088ab;
   --milan-text: #043542;
 }
 
 .bg-turquoise {
   background-color: var(--milan-turquoise);
 }
 
 .bg-light-turquoise {
   background-color: var(--milan-light-turquoise);
 }
 
 .bg-dark-turquoise {
   background-color: var(--milan-dark-turquoise);
 }
 
 .border-turquoise {
   border: 1px solid var(--milan-dark-turquoise);
   border-radius: 9px;
 }
 .border-radius {
   border-radius: 9px;
 }
 
 .font-dark-turquoise {
   color: var(--milan-dark-turquoise);
 }
 
 #store-rank .width-limit {
   max-width: 440px;
 }
 
 #store-rank .width-limit-percent {
   width: 90%;
 }
 
 #store-rank .form-control {
   border-color: #00a5b4;
 }
 #store-rank .form-control:focus {
   box-shadow: 0 0 0 2px #00a5b4;
   transition: all 0.2s ease-in-out;
 }
 
 #store-rank h1 {
   font-size: 1.6rem;
 }
 
 #store-rank .milan-fp {
   font-size: 0.75rem;
   /* line-height: 0.2; */
 }
 
 #store-rank .milan-fp p {
   margin-bottom: 0;
 }
 
 .milan-btn {
   background-color: var(--milan-dark-turquoise);
   border: none;
 }
 
 .milan-btn:hover {
   background-color: #00a5b4;
 }
 
 .milan-btn:active {
   background-color: #00a5b4 !important;
 }
 
 #store-rank p {
   color: var(--milan-text);
 }
 
 #store-rank div.dollar-sign {
   position: relative;
 }
 
 #store-rank div.dollar-sign input {
   padding-left: 22px;
 }
 
 #store-rank div.dollar-sign:after {
   position: absolute;
   left: 6px;
   top: 6px;
   content: "$";
   font-size: 1rem;
   color: var(--milan-text);
 }
 
 #store-rank div.percentage-sign {
   position: relative;
 }
 
 #store-rank div.percentage-sign:after {
   position: absolute;
   right: 6px;
   top: 6px;
   content: "%";
   font-size: 1rem;
   color: var(--milan-text);
 }
 
 #store-rank input::-webkit-outer-spin-button,
 #store-rank input::-webkit-inner-spin-button {
   display: none;
 }
 
 .home-btn {
   z-index: 4;
   position: absolute;
   color: white;
 }